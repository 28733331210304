@use '@/styles/utils/mixins.scss' as *;

.mainStepperWrapper {
  .headingWrapper {
    margin-bottom: 3.75rem;

    @include mob() {
      padding: 0 1.875rem;
    }
  }

  @include mob() {
    margin-bottom: 6.25rem;
  }

  .boxWrapper {
    background-color: $neutral02;
    border-radius: 1.25rem;
    padding: 1.5rem 1.5rem 3.75rem;

    @include mob() {
      padding: 2rem 1.875rem;
    }

    .topButtonWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .backBtn {
        display: flex;
        gap: 1.25rem;
        font-weight: 500;
      }

      .backBtnTest {
        @include mob() {
          display: none;
        }
      }

      .closeIcon {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .nextButtonWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5rem 3.75rem 0;

      @include tab() {
      margin: 5rem 0;
      }

      @include mob() {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 2.25rem;
        margin: 5rem 0 0;
      }
    }

    .singleButton {
      justify-content: flex-end;
    }

    .stepHeadingWrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: 5rem;

      @include mob() {
        margin: 3rem 0rem;
      }

      .stepHeading {
        margin-bottom: 0.5rem;

        @include mob() {
          width: 100%;
        }
      }
    }
  }
}
